<template>
  <div @click="hideAllDropdown">
    <!-- Breadcrumb Navigation -->
    <BreadCrumb
      title="Legal Research"
      subtitle="Case Law Search / Search Result"
    />

    <!-- Back Button -->
    <div
      class="d-flex justify-content-start cursor-pointer back mt-3"
      @click="goBack"
    >
      <span class="material-symbols-rounded">arrow_back</span>
      <span class="mx-2">Back</span>
    </div>

    <!-- Search and Filters Section -->
    <div class="container content-search-section mt-4" style="max-width: 900px">
      <section>
        <!-- Search Bar -->
        <div class="search-and-filter">
          <form class="searchbar">
            <div class="entry">
              <span class="material-symbols-rounded entry-icon">search</span>
              <p class="truncateTitle">{{ truncatedTitle }}</p>
            </div>

            <!-- Country Dropdown -->
            <div class="right">
              <div class="search">
                <!-- <div class="country" id="country_dropdown">
                  <div class="country-and-flag" v-if="filterCountryContents.length" @click="toggleCountry">
                    <img :src="countryImages[countryNames[filterCountryContents[0]]]" alt="Country Flag" class="country-flag" />
                    <span class="state">{{ countryNames[filterCountryContents[0]] }}</span>
                    <span class="material-symbols-rounded dropdown-icon">
                      arrow_drop_down
                    </span>
                  </div>

                  <div v-if="isCountry" class="country-option">
                    <ul>
                      <li
                        v-for="(country, index) in filterCountryContents"
                        :key="index"
                        class="countryOption"
                        @click="selectCountry(country)"
                      >
                        <img :src="countryImages[countryNames[country]]" alt="Country Flag" class="country-flag" />
                        <span>{{ countryNames[country] }}</span>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <!-- Search Button -->
                <button
                  class="search-button"
                  aria-label="Search"
                  @click.prevent="filterResults"
                >
                  <span class="material-symbols-rounded search-icon">send</span>
                </button>
              </div>
            </div>
          </form>

          <!-- Filters -->
          <div class="filter">
            <div>
              <div class="jurisdiction-filter" @click="toggleCourt">
                <div class="filter-header">
                  <span class="material-symbols-rounded filter-icon me-2"
                    >language</span
                  >
                  <span class="filter-title">Jurisdiction and Courts</span>
                  <span class="material-symbols-rounded dropdown-icon"
                    >arrow_drop_down</span
                  >
                </div>
                <div class="selected-courts">
                  <div class="badge-content">
                    <span
                      v-for="(court, index) in selectedCourts"
                      :key="index"
                      class="badge-title badge"
                    >
                      {{ court }}
                      <span
                        class="material-symbols-rounded badge-icon"
                        @click="removeCourt(court)"
                        >close</span
                      >
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="courtOpen" class="court-name-selector">
                <label for="court-name" class="court-name mb-1"
                  >Court Name</label
                >
                <input
                  type="text"
                  placeholder="Type Court Name Here..."
                  v-model="searchCourts"
                  class="search_court mb-2"
                  @input="filterResults"
                />

                <div class="court-list">
                  <ul>
                    <li
                      v-for="(court, index) in filteredCourts"
                      :key="index"
                      @click="toggleCourtSelection(court)"
                      class="court-item"
                      :class="{ 'click-to-remove': isSelected(court) }"
                      @mouseenter="hoveredCourt = court"
                      @mouseleave="hoveredCourt = null"
                    >
                      {{ court }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- Clear Filters -->
            <div class="clear-filter">
              <button
                v-if="showResetButton"
                class="clear-button"
                @click="ResetAllFilters"
              >
                Reset
              </button>
              <!-- <button class="clear-button" @click="clearAllFilters">Clear</button> -->
            </div>
          </div>

          <div class="advance-search">
            <button class="filter-btn pe-none">
              <span class="material-symbols-rounded filter-icon me-2"
                >filter_list</span
              >
              <span>Filters :</span>
            </button>
            <!-- Advanced Search Toggle -->
            <div>
              <div class="badge" @click="toggleDate">
                <div class="badge-content">
                  <!-- Display selected date range or 'Any Date' -->
                  <span class="badge-title">{{ dateRangeDisplay }}</span>
                  <span class="material-symbols-rounded badge-icon"
                    >keyboard_arrow_down</span
                  >
                </div>
              </div>

              <div v-if="isDate" class="search-card">
                <!-- Publication Date Filters -->
                <div class="section">
                  <h6 class="public_title">Publication Date</h6>
                  <div class="date-inputs">
                    <input
                      type="date"
                      v-model="startDate"
                      @input="onDateInput"
                      class="custom-date-input"
                    />
                    <input
                      type="date"
                      v-model="endDate"
                      @input="onDateInput"
                      class="custom-date-input"
                    />
                  </div>

                  <!-- Year Range Radios -->
                  <div class="radio-group">
                    <div
                      class="radio-option"
                      v-for="option in yearOptions"
                      :key="option.value"
                    >
                      <input
                        type="radio"
                        :id="option.id"
                        :value="option.value"
                        v-model="years"
                        @change="handleYearChange(option)"
                      />
                      <label :for="option.id">{{ option.label }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Citation -->
            <div>
              <div class="badge" @click="toggleCitation">
                <div class="badge-content">
                  <span class="badge-title">Citation Count</span>
                  <span class="material-symbols-rounded badge-icon"
                    >keyboard_arrow_down</span
                  >
                </div>
              </div>
              <div v-if="showCitation" class="case-form-container">
                <b-form-group
                  class="public_title"
                  label="Citation Count"
                  label-for="citation"
                >
                  <b-form-input
                    type="number"
                    id="citation"
                    v-model="citation"
                    placeholder="Enter Max Citation Count"
                    class="custom-input"
                    @input="filterResults"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <span class="divider"></span>
            <!-- Case Title -->
            <div>
              <div class="badge" @click="toggleCase">
                <div class="badge-content">
                  <span class="badge-title">Case Title</span>
                  <span class="material-symbols-rounded badge-icon"
                    >keyboard_arrow_down</span
                  >
                  <span class="case-icon"></span>
                </div>
              </div>
              <div v-if="caseOpen" class="case-form-container">
                <b-form-group
                  class="public_title"
                  label="Case Title"
                  label-for="caseTitle"
                >
                  <b-form-input
                    id="caseTitle"
                    v-model="caseTitle"
                    placeholder="Enter Case Title"
                    class="custom-input"
                    @input="filterResults"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <!-- Abstract Title -->
            <div>
              <div class="badge" @click="toggleAbstract">
                <div class="badge-content">
                  <span class="badge-title">Abstract</span>
                  <span class="material-symbols-rounded badge-icon"
                    >keyboard_arrow_down</span
                  >
                  <span class="abstract-icon"></span>
                </div>
              </div>
              <div v-if="isAbstract" class="case-form-container">
                <b-form-group
                  class="mt-2 public_title"
                  label="Abstract"
                  label-for="caseAbstract"
                >
                  <b-form-input
                    id="caseAbstract"
                    v-model="abstract"
                    placeholder="Enter Abstract"
                    class="custom-input"
                    @input="filterResults"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <!-- Summary Status Badge -->
            <div>
              <div class="badge" @click="toggleSummary">
                <div class="badge-content">
                  <span class="badge-title">Summary Status</span>
                  <span class="material-symbols-rounded badge-icon"
                    >keyboard_arrow_down</span
                  >
                  <span class="status-icon"></span>
                </div>
              </div>
              <div v-if="summary" class="summary-form-container">
                <div class="section">
                  <h6 class="public_title">Filter by Status</h6>
                  <div class="date-inputs">
                    <div class="radio-group">
                      <div
                        class="radio-option"
                        v-for="status in filterStatus"
                        :key="status.id"
                      >
                        <input
                          type="radio"
                          :id="status.id"
                          :value="status.name"
                          v-model="selectedStatus"
                          @change="filterResults"
                        />
                        <label :for="status.id">{{ status.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Page Loader -->
        <PageLoader v-if="pageLoading" />

        <!-- Main Content -->
        <div v-else>
          <section>
            <main class="content">
              <!-- Header with Case Count -->
              <header class="content-header">
                <h1 class="title">
                  <span class="case-law">Case Law</span>
                  <span class="case-count"
                    >({{ filtered_searched_data.length }})</span
                  >
                </h1>
                <div>
                  <div class="sort-options swap" @click="toggleDropdown">
                    <span class="material-symbols-rounded">swap_vert</span>
                    <p>Sort: By Relevance</p>
                    <span class="material-symbols-rounded"
                      >keyboard_arrow_down</span
                    >
                  </div>

                  <!-- Dropdown for sorting options -->
                  <div v-if="isDropdownVisible" class="sort-form-container">
                    <b-form-group
                      class="sort-title"
                      label="Sort By"
                      label-for="caseTitle"
                    >
                      <p class="swap-p" @click="sortBy('A to Z')">A to Z</p>
                      <p class="swap-p" @click="sortBy('Relevance')">
                        Relevance
                      </p>
                    </b-form-group>
                  </div>
                </div>
              </header>

              <!-- Conditional Rendering Based on Filtered Data -->
              <div v-if="filtered_searched_data.length">
                <!-- List of Cases -->
                <CaseList
                  caseType="CaseLawSearch"
                  :filtered_searched_data="paginatedJurisdictions"
                  :caseTitle="caseTitle"
                  :abstract="abstract"
                />

                <!-- Pagination Controls -->
                <div
                  v-if="filtered_searched_data.length > perPage"
                  class="pagination"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="filtered_searched_data.length"
                    :per-page="perPage"
                    @change="onPageChange"
                  ></b-pagination>
                </div>
              </div>

              <!-- No Data Message -->
              <div v-else class="no-data-message">
                <div class="empty-placeholder">
                  <img :src="emptyImg" alt="No Content" />
                  <h5>No Case Law Found</h5>
                  <p class="empty-text">{{ emptyText }}</p>
                </div>
              </div>
            </main>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { BPagination } from "bootstrap-vue";
import BreadCrumb from "../../../components/misc/BreadCrumb.vue";
import CaseList from "../CaseList.vue";
import PageLoader from "./../Loader.vue";
import casesearch from "../../../store/casesearch";
import countryImages from "./../../../../src/assets/country/index";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import emptyImg from "./../../../../src/assets/misc/empty.svg";

export default {
  components: {
    BreadCrumb,
    CaseList,
    BPagination,
    PageLoader,
  },
  data() {
    return {
      // Pagination
      currentPage: 1,
      perPage: 10,
      isDropdownVisible: false,
      emptyImg,
      // Dropdown Controls
      isCountry: false,
      isDate: false,
      caseOpen: false,
      courtOpen: false,
      showCitation: false,
      isAbstract: false,
      summary: false,
      addFilter: false,
      showResetButton: true,

      // Search and Filters
      selectedCountry: null,
      searchQuery: "",
      countryImages,
      selectedCourts: [],
      searchCourts: "",
      caseTitle: "",
      abstract: "",
      selectedCase: "All Cases",
      publicationType: "",
      years: "",
      startDate: "",
      endDate: "",
      citation: 0,
      selectedStatus: "",

      // Data Handling
      permissions: localStorage.permissions,
      pageLoading: true,
      campaign: [],
      searched_data: [],
      filtered_searched_data: [],

      // Filters Data
      filterCountryContents: [],
      filterCourtContents: [],
      filterStatus: [
        { id: "summarized", name: "Summarized" },
        { id: "summarizing", name: "Summarizing" },
        { id: "unsummarized", name: "Unsummarized" },
        { id: "all", name: "All Status" },
      ],

      // Additional Data
      hoveredCourt: null,
      timer_count: 0,
      timeout_timer: null,

      // Constants
      countryNames: {
        "United States": "USA",
        "United Kingdom": "UK",
        Australia: "AUS",
        Canada: "CAN",
        "New Zealand": "NZ",
        Singapore: "SG",
        Malaysia: "MY",
      },
      yearOptions: [
        { id: "last5", value: "5", label: "Last 5 Years" },
        { id: "last10", value: "10", label: "Last 10 Years" },
        { id: "last15", value: "15", label: "Last 15 Years" },
        { id: "anyDate", value: "any", label: "Any Date" },
      ],
      publicationTypes: [
        { id: "published", value: "published", label: "Published" },
        { id: "unpublished", value: "unpublished", label: "Unpublished" },
      ],

      // Title Property
      title: "",

      // Additional properties if needed
      searchType: "",
      completedPercentage: 0,
      emptyText:
        "We couldn’t find any case law matching your search. Try refining your filters or using different keywords to improve your results.",
    };
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    // Navigation Methods
    hideAllDropdown() {
      this.$root.$emit("bv::hide::tooltip");
    },
    goBack() {
      this.$router.push({ name: "CaseLawSearchHistory" });
    },
    removeCourt(court) {
      this.selectedCourts = this.selectedCourts.filter(
        (selectedCourt) => selectedCourt !== court
      );
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    sortBy(criteria) {
      if (criteria === "A to Z") {
        this.filtered_searched_data = [...this.filtered_searched_data].sort(
          (a, b) => a.name.localeCompare(b.name)
        );
      } else if (criteria === "Relevance") {
        this.filtered_searched_data = this.getRelevanceSortedData();
      }
    },
    getRelevanceSortedData() {
      return [...this.filtered_searched_data].sort(
        (a, b) => b.verify - a.verify
      );
    },
    // toggleFilter() {
    //   this.addFilter = !this.addFilter;
    //   if (this.addFilter) {
    //     this.showResetButton = true;
    //   }
    // },

    ResetAllFilters() {
      // this.showResetButton = false;
      // this.addFilter = false;
      this.courtOpen = false;
      this.isDate = false;
      this.showCitation = false;
      this.caseOpen = false;
      this.isAbstract = false;
      this.summary = false;
      this.clearAllFilters();
    },
    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    toggleDate() {
      this.isDate = !this.isDate;
    },
    toggleCase() {
      this.caseOpen = !this.caseOpen;
    },
    toggleCourt() {
      this.courtOpen = !this.courtOpen;
    },
    toggleCitation() {
      this.showCitation = !this.showCitation;
    },
    toggleAbstract() {
      this.isAbstract = !this.isAbstract;
    },
    toggleSummary() {
      this.summary = !this.summary;
    },

    // Filter Handling
    async fetchData() {
      try {
        let complete = false;

        while (!complete) {
          const response = await casesearch.GetJurisdiction(
            this.$route.params.id
          );
          const DATA = response.data.data;

          // Check if jurisdictions are not empty and completePercentage is >= 100
          if (DATA.completedPerCentage >= 100) {
            complete = true; // Exit the loop if conditions are met
          } else {
            // If conditions are not met, wait for a while before refetching
            console.log(
              "Data not complete or empty jurisdictions. Refetching..."
            );
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
          }

          // Update to local state based on received DATA
          this.pageLoading = DATA.completedPerCentage < 100;
          this.campaign = DATA;

          // Parse title and searchType
          this.title = this.parseJSONField(DATA.statement);
          this.searchType = this.parseJSONField(DATA.type);

          // Populate searched data
          this.searched_data = DATA.jurisdictions.filter(
            (j) => j.content !== "Can't Read File"
          );
          this.filtered_searched_data = [...this.searched_data];

          // Extract unique courts and countries
          this.extractFilters();

          // Sort data
          this.sortData();

          // Apply initial filters
          this.filterResults();
        }
      } catch (error) {
        console.error(error);
      }

      // If polling for updates is unnecessary, you can comment out the following line
      // this.startPolling();
    },
    parseJSONField(field) {
      try {
        return JSON.parse(field).join(", ");
      } catch {
        return field;
      }
    },
    extractFilters() {
      const courtsSet = new Set();
      const countriesSet = new Set();

      this.searched_data.forEach((item) => {
        courtsSet.add(item.court);
        countriesSet.add(item.type);
      });

      this.filterCourtContents = Array.from(courtsSet);
      this.filterCountryContents = Array.from(countriesSet);
    },
    sortData() {
      const sortByVerify = (a, b) => b.verify - a.verify;
      this.searched_data.sort(sortByVerify);
      this.filtered_searched_data.sort(sortByVerify);
    },

    // Polling for Data Updates
    startPolling() {
      const loop = async () => {
        if (this.timer_count >= 15 || !this.$route.params.id) return;
        this.timer_count++;
        try {
          const response = await casesearch.GetJurisdiction(
            this.$route.params.id
          );
          this.pageLoading = response.data.data.completedPerCentage < 100;
          response.data.data.jurisdictions.forEach((updatedItem) => {
            const index = this.filtered_searched_data.findIndex(
              (item) => item.id === updatedItem.id
            );
            if (index !== -1) {
              this.$set(
                this.filtered_searched_data[index],
                "completedPerCentage",
                updatedItem.completedPerCentage
              );
            }
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.timeout_timer = setTimeout(loop, 20000);
        }
      };
      loop();
    },

    // Filter Results Based on Selected Criteria
    filterResults() {
      let results = [...this.searched_data];

      // Publication Type Filter
      if (this.publicationType) {
        results = results.filter(
          (item) => item.publicationType === this.publicationType
        );
      }

      // Date Range Filter
      if (this.startDate || this.endDate) {
        results = results.filter((item) => {
          const itemDate = moment(item.date, "YYYY-MM-DD");
          if (
            this.startDate &&
            !itemDate.isSameOrAfter(moment(this.startDate, "YYYY-MM-DD"))
          )
            return false;
          if (
            this.endDate &&
            !itemDate.isSameOrBefore(moment(this.endDate, "YYYY-MM-DD"))
          )
            return false;
          return true;
        });
      }

      // Case Title Filter
      if (this.caseTitle) {
        const titleLower = this.caseTitle.toLowerCase();
        results = results.filter((item) =>
          item.name.toLowerCase().includes(titleLower)
        );
      }

      // Abstract Filter
      if (this.abstract) {
        const abstractLower = this.abstract.toLowerCase();
        results = results.filter((item) =>
          item.content.toLowerCase().includes(abstractLower)
        );
      }

      // Courts Filter
      if (this.selectedCourts.length) {
        results = results.filter((item) =>
          this.selectedCourts.includes(item.court)
        );
      }

      // Summarize
      if (this.selectedStatus) {
        if (this.selectedStatus === "All Status") {
          console.log(this.selectedStatus);
        } else {
          results = results.filter((item) => {
            const status = this.getStatus(item.completedPerCentage);
            return status === this.selectedStatus.toLowerCase();
          });
        }
      }

      // Citation Count Filter
      if (this.showCitation && this.citation) {
        if (this.showCitation && this.citation) {
          results = results.filter(
            (item) => Number(item["cite-count"]) <= this.citation
          );
        }
      }

      this.filtered_searched_data = results;
    },
    getStatus(completedPercentage) {
      // Example function to return status based on completed percentage
      if (completedPercentage >= 100) {
        return "summarized";
      } else if (completedPercentage > 0) {
        return "summarizing";
      } else {
        return "unsummarized";
      }
    },

    // Handling Year Range Selection
    handleYearChange(option) {
      if (option.value !== "any") {
        const currentYear = new Date().getFullYear();
        const yearsAgo = currentYear - parseInt(option.value);
        this.startDate = `${yearsAgo}-01-01`;
        this.endDate = `${currentYear}-12-31`;
      } else {
        this.startDate = "";
        this.endDate = "";
      }
      this.filterResults();
    },

    // Court Selection Handling
    toggleCourtSelection(court) {
      const index = this.selectedCourts.indexOf(court);
      if (index > -1) {
        this.selectedCourts.splice(index, 1);
      } else {
        this.selectedCourts.push(court);
      }
      this.filterResults();
    },
    isSelected(court) {
      return this.selectedCourts.includes(court);
    },

    // Country Selection
    selectCountry(country) {
      this.selectedCountry = country;
      this.isCountry = false;
      this.filterResults();
    },

    // Clear All Filters
    clearAllFilters() {
      // this.publicationType = '';
      this.years = "";
      this.startDate = "";
      this.endDate = "";
      this.caseTitle = "";
      this.abstract = "";
      this.selectedCourts = [];
      this.selectedStatus = "All Status";
      this.citation = 0;
      this.filterResults();
    },

    // Date Input Handling
    onDateInput() {
      if (this.years) {
        this.years = "";
      }
      this.filterResults();
    },
  },
  computed: {
    truncatedTitle() {
      if (!this.title) return "";
      const words = this.title.split(" ");
      return words.length > 8
        ? `${words.slice(0, 8).join(" ")}...`
        : this.title;
    },
    filteredCourts() {
      return this.filterCourtContents.filter((court) =>
        court.toLowerCase().includes(this.searchCourts.toLowerCase())
      );
    },
    paginatedJurisdictions() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filtered_searched_data.slice(start, end);
    },
    dateRangeDisplay() {
      if (this.startDate && this.endDate) {
        return `${this.startDate} - ${this.endDate}`;
      } else {
        return "Any Date";
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  beforeDestroy() {
    clearTimeout(this.timeout_timer);
  },
};
</script>

<style scoped>
#Header {
  background: #fff !important;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5rem 1.5rem 0;
}

.left {
  color: var(--primary);
  cursor: pointer;
}

.left p {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary) !important;
}

.right {
  cursor: pointer;
  background: var(--primary);
  color: #fff;
  gap: 4px;
  border-radius: 6px;
}

.right p {
  font-size: 16px;
  font-weight: 500;
  color: #fff !important;
}

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 880px;
}
</style>

<style scoped>
.case-summary-content {
  justify-content: center;
  border-radius: 8px;
  background: #fff;
  display: flex;
  max-width: 880px;
  flex-direction: column;
  padding: 0px 30px 32px;
}

.tabs-header {
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  justify-content: flex-start;
}

.tabs {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
}

.statement {
  color: #0e4485 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 8px;
}

.limitations-description {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0px;
}

.content {
  display: flex;
  margin-top: 16px;
  width: 100%;
  max-width: 816px;
  flex-direction: column;
  justify-content: center;
}

.content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 16px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0px !important;
}

.case-law {
  margin-right: 8px;
}

.case-count {
  color: #666;
  font-size: 18px;
}

.sort-options {
  width: 215px !important;
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
}

.sort-options:hover {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-Light-Grey, #f2f3f3);
}

.sort-options p {
  margin: 0 8px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.title {
  padding: 0px 10px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.case-law {
  color: #383a3e;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  align-self: stretch;
  margin: auto 0;
}

.case-count {
  color: #86888d;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  align-self: stretch;
  margin: auto 0;
}

@media (max-width: 991px) {
  .case-summary-content {
    padding: 0 20px;
  }

  .tabs,
  .content-header,
  .title {
    max-width: 100%;
  }

  .divider {
    max-width: 100%;
  }
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>

<style scoped>
/* Styling the Date Picker */
.date-picker-container {
  padding: 5px;
  max-width: 300px;
  margin: 20px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
}

.custom-datepicker {
  background: red;
  width: 100%;
}

/* Adjusting the style for the 'Any Date' checkbox */
.any-date-checkbox {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.any-date-checkbox b-form-checkbox {
  margin-left: 5px;
}

/* Optional styling for date picker inputs */
input.form-control {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>
<style scoped>
.search-card {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 300px;
}

.section {
  margin-bottom: 20px;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

.date-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.date-inputs input {
  width: 48%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.radio-option {
  display: flex;
  align-items: center;
}

.radio-option input {
  margin-right: 8px;
}

.radio-option label {
  font-size: 14px;
  color: #666;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  transform: scale(1.1);
  accent-color: var(--primary);
}

input[type="text"]::placeholder {
  color: #aaa;
  font-size: 12px;
}

/* Media query for responsive layout */
@media (max-width: 600px) {
  .search-card {
    width: 100%;
  }

  .date-inputs input {
    width: 100%;
    margin-bottom: 8px;
  }
}
</style>
<style scoped>
.search {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #86888d;
  font: 400 14px/24px Poppins, sans-serif;
}

.country {
  display: flex;
  align-items: center;
}

.country-and-flag {
  margin: 0 0 0 10%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.country-flag {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.dropdown-icon {
  cursor: pointer;
}

.dropdown {
  left: -65% !important;
  height: 451px;
  overflow-y: auto;
  width: 231px !important;
  position: absolute;
  top: 160%;
  left: 0;
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 12px;
}

.state-search {
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #f2f3f3;
  background: #fafbfc;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.state-option {
  padding: 8px;
  cursor: pointer;
}

.state-option:hover {
  background-color: #f0f0f0;
}
</style>
<!-- Search bar -->
<style scoped>
#Search {
  height: 44px;
  border: 1px solid var(--light-grey);
  border-radius: 8px;
  display: flex;
  align-items: center;
}

#Search span {
  margin: 12px;
  color: var(--dark-grey);
}

#Search input {
  width: calc(100% - 60px);
}

.form-footer {
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px;
  font: 500 16px Poppins, sans-serif;
}
</style>

<style scoped>
.navbar {
  padding: 0px;
  margin-left: -20px;
  margin-right: -20px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 8px 20px;

  &.active {
    border-bottom: 2px solid var(--Primary-Blue, #0e4485);
    color: var(--Primary-Blue, #0e4485);
  }
}

h3,
p {
  margin-bottom: 0px;
}

#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  align-items: center;
  background-color: white;
}

.add {
  background-color: var(--mid-grey);
  border-radius: 50px;
}

/* Client */
.client_title {
  color: var(--neutral-black) !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.client_card {
  background: var(--interface-grey);
  width: 816px;
  height: 96px;
  border-radius: 8px;
  border: var(--light-grey);
  padding-top: 1rem;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.gw {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.radio {
  width: 20px;
  height: 20px;
}

.border-btm {
  border-bottom: 1px solid var(--mid-grey);
}

.btn {
  padding: 4px 16px;
  border-radius: 4px;
  height: 32px;
  justify-content: center;
}

.cancel-button {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.cancel-button:hover {
  background: var(--primary);
  color: #fff;
}
.arrow {
  width: 24px;
  height: 24px;
}

.border_error {
  outline: 1px solid var(--status-red);
  width: 824px;
  height: 140px;
  border-radius: 8px;
  padding: 4px;
}

.background_error {
  background-color: #ff4d4f1a;
}

.type_error {
  width: 420px;
  height: 33px;
  padding: 4px;
  border-radius: 8px;
  outline: 1px solid var(--status-red);
}

.sm {
  color: var(--status-red);
}

#Error {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon_info {
    vertical-align: middle;
    font-size: 18px;
    color: var(--neutral-black);
    font-variation-settings: "FILL" 1;
  }
}

p {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-black);
}

.delete-prompt .bg {
  color: var(--dark-grey) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
</style>
<style scoped>
.custom-radio-wrapper {
  display: flex;
  align-items: center;
}

.custom-radio-input {
  position: absolute;
  opacity: 0;
}

.custom-radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}

.custom-radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dark-grey);
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-circle {
  background-color: var(--light-blue);
  border-color: var(--primary);
}

.custom-radio-circle::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  transform: scale(0);
  transition: transform 0.3s;
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-circle::after {
  transform: scale(1);
}

.custom-radio-text {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--dark-grey);
}

.custom-radio-input:checked + .custom-radio-label .custom-radio-text {
  color: var(--primary);
}

.history-btn {
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.history-btn:hover {
  background: var(--primary);
  color: #fff;
}
</style>

<style scoped>
.publication-filter {
  border-radius: 8px;
  border: 1px solid #f2f3f3;
  background: #fff;
  box-shadow: 0 4px 10px rgba(170, 171, 175, 0.6);
  color: #383a3e;
  font: 400 14px/24px Poppins, sans-serif;
  padding: 10px;
  width: 286px;
}

.filter-title {
  font-weight: 500;
  line-height: 1;
  margin: 0;
}

.date-range {
  display: flex;
  gap: 18px;
  justify-content: space-between;
  padding: 6px 16px;
}

.date-input {
  border-radius: 4px;
  border: 1px solid #f2f3f3;
  background: #fafbfc;
  color: #86888d;
  font-size: 12px;
  padding: 4px 8px;
  width: calc(50% - 9px);
}

.year-options,
.publication-options {
  border: none;
  margin: 0;
  padding: 0;
}

.option-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 4px 16px;
}

.radio-input {
  appearance: none;
  border: 1px solid #86888d;
  border-radius: 50%;
  height: 18px;
  margin: 0;
  width: 18px;
}

.radio-input:checked {
  background-color: #383a3e;
  border-color: #383a3e;
}

.radio-label {
  cursor: pointer;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>

<style scoped>
.content-search-section {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
@media (max-width: 991px) {
  .content-search-section {
    padding: 0 20px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
}

.case-law-search {
  color: #383a3e;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}
.description {
  color: #86888d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.search-and-filter {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 16px;
}
.searchbar {
  padding-left: 20px;
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
.entry {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #d1d2d5;
  width: 100%;
  box-sizing: border-box;
}

.entry-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  color: #333;
}

.entry-input::placeholder {
  color: #888;
}

.entry-icon {
  color: #555;
}
.right {
  border: 1px solid var(--light-grey);
  background: var(--interface-grey);
  display: flex;
  align-items: center;
  gap: 8px;
}
.country {
  display: flex;
  justify-content: space-between;
}
.state {
  margin: 0;
}

.dropdown-icon {
  width: 18px;
}
.search-button {
  display: flex;
  justify-content: center;
  background: #0e4485;
  border: none;
  padding: 8px;
  border-radius: 0 8px 8px 0 !important;
  opacity: 0.6;
  cursor: pointer;
}
.search-button:hover {
  background: var(--primary);
  opacity: 1;
  color: #fff;
}
.search-icon {
  color: #fff;
}
.filter {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  font-family: Poppins, sans-serif;
  color: #86888d;
  font-weight: 400;
  line-height: 24px;
  justify-content: space-between;
  position: relative;
}

.advance-search {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
.advanced-search-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: transparent;
  padding: 4px 8px;
  cursor: pointer;
}
.advanced-search-button:hover {
  background: #f2f3f3;
}
.button-icon {
  width: 18px;
  color: #86888d;
}

.button-title {
  color: #86888d;
  font-size: 14px;
  margin: 0;
}
.separation-line {
  width: 1px;
}

.badge {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  cursor: pointer;
}
.badge-filter {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 2px 8px;
  border-radius: 1000px;
}
.badge-content {
  display: flex;
  align-items: center;
}

.badge-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.badge-icon {
  font-size: 20px;
  color: #86888d;
  width: 18px;
  cursor: pointer;
}
.clear-filter {
  align-self: center;
}

.clear-button {
  border: none;
  background: transparent;
  color: #86888d;
  font-size: 14px;
  cursor: pointer;
}
.dropdown-content {
  max-height: 200px;
  overflow-y: auto;
}
#TableListDropdown {
  width: 231px;
}
#AdvaceDropdown {
  width: 500px !important;
}
.states-header {
  margin-left: 4px;
  margin-bottom: 10px;
  text-align: left;
}

.state-input {
  width: 90%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid var(--light-grey);
  background: var(--interface-grey);
}
</style>
<style scoped>
.dropdown-modal {
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  font: 400 14px/24px Poppins, sans-serif;
  color: var(--Neutral-Black, #383a3e);
}

.publication-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.publication-fieldset {
  border: none;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.publication-legend {
  font-size: 12px;
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-bottom: 8px;
}
.date-input {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 8px;
  width: calc(50% - 8px);
}

.radio-link {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 16px;
}

.radio-button {
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
}

.accessible-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
<style scoped>
.dropdown-modal {
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}
.publication-date {
  display: flex;
  flex-direction: column;
  width: 266px;
}

.start-date {
  align-self: flex-start;
  border-radius: 4px;
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 2px 8px;
  font: 400 12px Poppins, sans-serif;
}
.dropdown-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  font: 400 12px/24px Poppins, sans-serif;
}
.select-or-type-a-date {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  padding: 4px 8px;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--Neutral-White, #fff);
  padding: 4px 16px;
}

.current-month {
  color: var(--Neutral-Black, #383a3e);
  text-align: center;
  font: 500 14px/1 Poppins, sans-serif;
}
.chevrons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.img {
  width: 18px;
}
.dropdown-link-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 16px;
  font: 400 14px/2 Poppins, sans-serif;
}
.picker {
  display: flex;
  flex-direction: column;
  padding: 1px 7px 8px;
}

.days-of-week,
.week {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.days-of-week {
  color: var(--Neutral-Dark-Grey, #86888d);
  margin-top: 26px;
}

.week > span {
  flex-grow: 1;
}

.next-month {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
}
.dropdown-link-3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 16px 4px;
}

.radio-button {
  border-radius: 50%;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Primary-Light-Blue, #e7ecf3);
  display: flex;
  width: 18px;
  height: 18px;
}

.radio-button::before {
  content: "";
  background: var(--Primary-Blue, #0e4485);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: auto;
}

.any-date {
  color: var(--Neutral-Black, #383a3e);
  font: 400 14px/24px Poppins, sans-serif;
}
.divider {
  margin-top: -0.5%;
  width: 1px !important;
  height: 27px !important;
  background-color: var(--mid-grey);
}
</style>
<style scoped>
.case-form-container,
.sort-form-container {
  width: 50%;
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  padding: 12px;
  max-width: 500px;
}
.sort-form-container {
  width: 25%;
}
.citation-form-container {
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  padding: 12px;
  max-width: 500px;
  margin: 20px;
}
.summary-form-container {
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  padding: 12px;
  max-width: 500px;
}
.filter-form-container {
  position: absolute;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  border-radius: 8px;
  background: #fff;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  padding: 12px;
  max-width: 500px;
}
.custom-input {
  border-radius: 5px;
  padding: 8px;
  border: 1px solid #ccc;
}

.radio-option {
  margin-top: 10px;
}

/* Optional Styling */
.b-form-group label {
  font-weight: bold;
}

.b-form-radio {
  margin-top: 15px;
}
</style>

<style scoped>
.court-name-selector {
  position: absolute;
  z-index: 1000;
  max-height: 400px;
  overflow-y: auto;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  width: 100%;
  max-width: 500px;
}
.court-name {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
}

.custom-multiselect {
  width: 100%;
}

.custom-tag {
  background-color: #e0e0e0;
  padding: 0px 10px;
  border-radius: 30px;
  display: inline-block;
  margin-right: 5px;
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.remove-tag {
  margin-left: 8px;
  cursor: pointer;
}

.court-list {
  margin-top: 10px;
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.court-item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.court-item:hover {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
}

.list-title {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.info {
  font-variation-settings: "FILL" 1;
  font-size: 17px;
  display: inline-block;
}
.clearall-button {
  border: none;
  border-radius: 20px !important;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: #86888d;
  font-size: 14px;
  cursor: pointer;
}
.red {
  color: var(--status-red);
  margin-left: auto;
}
.search_court {
  width: 100%;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
}
.select_date {
  padding: 4px 8px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
}
.any_date {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.date_container {
  margin-left: 40%;
  margin-top: -5%;
}
.country-option {
  width: 138px !important;
  z-index: 100;
  position: absolute;
  top: 160%;
  left: 0;
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 100%;
  padding: 12px;
}
.countryOption {
  margin-bottom: 10px;
}
.countryOption:hover {
  background: var(--Neutral-Light-Grey, #f2f3f3);
}
.public_title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
#Header {
  margin: -20px -20px 0px -20px;
  padding: 16px 20px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
  }

  & button {
    margin-left: 50px;
  }
}
.truncateTitle {
  color: var(--Neutral-Black, #383a3e);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
.status-icon,
.case-icon,
.abstract-icon {
  width: 8px;
  height: 8px;
  background: #faad14;
  border-radius: 50%;
  position: relative;
}
.status-icon {
  margin-top: -21.5%;
}
.case-icon {
  margin-top: -34%;
}
.abstract-icon {
  margin-top: -36%;
}
.no-data-message {
  padding: 50px 20px;
}
.empty-text {
  text-align: center;
  margin: 0 17%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.filter-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  font: 500 14px Poppins, sans-serif;
  color: #86888d;
  cursor: pointer;
}

.jurisdiction-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.filter-header {
  padding: 5px 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #86888d;
}
.filter-header:hover {
  background: #f2f3f3;
  border-radius: 4px;
}

.filter-icon,
.dropdown-icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;
}

.selected-jurisdictions {
  display: flex;
  gap: 8px;
}

.jurisdiction-badge {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f2f3f3;
  border: 1px solid #d1d2d5;
  border-radius: 1000px;
  padding: 0 8px;
  font-size: 12px;
  color: #86888d;
}

.remove-icon {
  font-size: 18px;
  cursor: pointer;
}

.jurisdiction-limit {
  font-size: 12px;
  color: #86888d;
  margin-top: 8px;
}
.swap {
  width: 25%;
}
.swap-p {
  color: var(--Neutral-Black, #383a3e);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 16px;
  cursor: pointer;
}
.pagination {
  float: right;
}
.sort-title {
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
}
</style>
